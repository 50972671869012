import styles from './Animation.module.scss';
import { RefObject } from 'react';
import Cell from '#images/animation/cell.svg';
import Phone from '#images/animation/phone.svg';
import Scanner from '#images/animation/scanner.png';
import { FirstStepProps } from '#types/MainAnimation';

const FirstStep = ({
  animationType = '',
  phone,
  active,
  mainRef,
  towerRef,
  phoneRef,
  boxCheckingRef,
  listRef,
  carrierRef,
  countryRef,
  timezoneRef,
  cityRef,
  locationRef,
  gpsRef,
  ipRef,
  subtitleRef,
  firstStepData,
}: FirstStepProps) => {
  const isLostPhone = animationType === 'lp';
  const isMain = animationType === 'main';
  const isIpTracker = animationType === 'ipTracker';

  const locationsRefsArray = [gpsRef, ipRef];
  const listRefsArray = [carrierRef, countryRef, timezoneRef, cityRef, locationRef];
  const isLocationFeature = (ref: RefObject<HTMLSpanElement>) => ref === locationRef;
  return (
    <section
      className={`${styles.animated} ${styles[animationType]} ${active ? styles.active : ''}`}
      ref={mainRef}
    >
      <div className={styles.animationWrapper}>
        <div className={`${styles.boxAnimated} ${styles[animationType]}`}>
          {isMain && (
            <div className={`${styles.boxHeader}`} ref={towerRef}>
              <div className={`${styles.boxIconAnimated}`}>
                <img
                  width="84"
                  height="84"
                  src={Cell.src}
                  alt="Cell icon"
                  loading="lazy"
                  decoding="async"
                />
              </div>
              <p className={styles.boxTextAnimated}>{firstStepData.towers}</p>
            </div>
          )}
          {isMain && (
            <div className={`${styles.boxHeader} ${styles.hide}`} ref={phoneRef}>
              <div className={styles.boxIconAnimated}>
                <img
                  width="78"
                  height="84"
                  src={Phone.src}
                  alt="Phone icon"
                  loading="lazy"
                  decoding="async"
                />
              </div>
              <p className={styles.boxTextAnimated}>{firstStepData.phone}</p>
            </div>
          )}
          {isIpTracker && (
            <div className={styles.boxHeader} ref={phoneRef}>
              <div className={styles.boxIconAnimated}>
                <img
                  width="78"
                  height="84"
                  src={Phone.src}
                  alt="Phone icon"
                  loading="lazy"
                  decoding="async"
                />
              </div>
            </div>
          )}
          {isLostPhone && (
            <div className={styles.scannerBgLp}>
              <img
                width="850"
                height="850"
                src={Scanner.src}
                alt="Scanner"
                loading="lazy"
                decoding="async"
              />
            </div>
          )}
          {isLostPhone && (
            <p
              className={`${styles.boxSearching} ${styles[animationType]} ${active ? styles.animate : ''}`}
            >
              {firstStepData.header}
            </p>
          )}
          {isIpTracker && (
            <p className={`${styles.boxTextAnimated} ${styles[animationType]}`}>
              {firstStepData.header}
            </p>
          )}
          {!isIpTracker && (
            <div className={`${styles.phone} ${styles[animationType]}`}>{phone}</div>
          )}
          <div className={`${styles.table} ${styles[animationType]}`} ref={listRef}>
            {!isLostPhone && !isIpTracker && (
              <p className={`${styles.boxChecking} ${styles[animationType]}`} ref={boxCheckingRef}>
                {firstStepData.checking}
              </p>
            )}
            {isIpTracker && (
              <>
                {firstStepData.locations?.map((title: string, index: number) => (
                  <div
                    key={title + index}
                    className={`${styles.tableRow} ${styles[animationType]}`}
                  >
                    <span className={styles.tableTitle}>{title}</span>
                    <span
                      className={`${styles.tableValue} ${styles[animationType]}`}
                      ref={locationsRefsArray[index]}
                    >
                      {firstStepData.defined}
                    </span>
                  </div>
                ))}
                <div className={`${styles.tableRow} ${styles[animationType]}`} ref={subtitleRef}>
                  <span className={`${styles.boxFlashingTextAnimated} ${styles.tableTitle}`}>
                    {firstStepData.subtitle}
                  </span>
                </div>
              </>
            )}
            {firstStepData.titles.map((title: string, index: number) => (
              <div key={title + index} className={`${styles.tableRow} ${styles[animationType]}`}>
                <span className={styles.tableTitle}>{title}</span>
                {isIpTracker || !isLocationFeature(listRefsArray[index]) ? (
                  <span
                    className={`${styles.tableValue} ${styles[animationType]}`}
                    ref={listRefsArray[index]}
                  ></span>
                ) : (
                  <span
                    className={`${styles.tableValue} ${styles.label} ${styles[animationType]}`}
                    ref={listRefsArray[index]}
                  >
                    {firstStepData.defined}
                  </span>
                )}
              </div>
            ))}
          </div>
          {isLostPhone && (
            <div className={`${styles.boxHeader} ${styles.lp}`} ref={towerRef}>
              <p className={styles.boxTextAnimated}>{firstStepData.towers}</p>
            </div>
          )}
          {isLostPhone && (
            <div className={`${styles.boxHeader} ${styles.lp} ${styles.hide}`} ref={phoneRef}>
              <p className={styles.boxTextAnimated}>{firstStepData.phone}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FirstStep;
