import styles from './EarthVideo.module.scss';
import EarthPoster from '#images/animation/poster.webp';
import PlanetWebm from '#videos/planet.webm';
import PlanetMp4 from '#videos/planet_540p.mp4';
import { RefObject } from 'react';

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
};

const EarthVideo = ({ videoRef }: Props) => {
  return (
    <video
      className={`${styles.bg} ${styles.video}`}
      loop
      muted
      playsInline
      poster={EarthPoster.src}
      ref={videoRef}
      preload="none"
    >
      <source src={PlanetWebm} type="video/webm" />
      <source src={PlanetMp4} type="video/mp4" />
    </video>
  );
};

export default EarthVideo;
