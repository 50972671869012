import styles from './Animation.module.scss';
import EarthVideo from '#components/ui/MainAnimation/EarthVideo';
import FirstStep from '#components/ui/MainAnimation/FirstStep';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from 'react';
import useResponsive from '#hooks/useResponsive';
import FinalStep from '#components/ui/MainAnimation/FinalStep';
import MapDesktop from '#images/animation/map.jpg';
import MapDesktopWebp from '#images/animation/map.webp';
import MapMobile from '#images/animation/map-mob.jpg';
import MapMobileWebp from '#images/animation/map-mob.webp';
import { scrollToTop } from '#utils/scroll';
import i18next from 'i18next';
import analytics from '#utils/analytics';
import { prepareUrl } from '#utils/prepareUrl';
import { ElementClassProps, MainProps } from '#types/MainAnimation';
import { initAnimation, prepareAnimation, resetAnimationGlob } from '#store/initAnimation';
import { phoneData } from '#store/phoneData';
import { loadingButton } from '#store/locate';
import { useStore } from '@nanostores/react';

const MainAnimation = ({
  animationType,
  firstStepData,
  finalStepData,
  currentLanguage,
  funnelLink = '',
}: MainProps) => {
  const animationContainerRef = useRef<HTMLDivElement>(null);
  const animationWrapperRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const firstStepRef = useRef<HTMLDivElement>(null);
  const finalStepRef = useRef<HTMLDivElement>(null);
  const towerRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const boxCheckingRef = useRef<HTMLParagraphElement>(null);
  const carrierRef = useRef<HTMLSpanElement>(null);
  const countryRef = useRef<HTMLSpanElement>(null);
  const timezoneRef = useRef<HTMLSpanElement>(null);
  const cityRef = useRef<HTMLSpanElement>(null);
  const gpsRef = useRef<HTMLSpanElement>(null);
  const ipRef = useRef<HTMLSpanElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const mapBgRef = useRef<HTMLImageElement>(null);
  const heroContainer = document.getElementById('hero');
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false);
  const [animateAnimation, setAnimateAnimation] = useState<boolean>(false);
  const [wrapperAnimation, setWrapperAnimation] = useState<boolean>(false);
  const [removeWrapperAnimation, setRemoveWrapperAnimation] = useState<boolean>(false);
  const [finalStepAnimation, setFinalStepAnimation] = useState<boolean>(false);
  const [scrollUp, setScrollUp] = useState<boolean>(false);
  const isMobile = useResponsive('mobile');
  const isSM = useResponsive('sm');
  const isFftbPage = window.location.pathname.includes('fftb');
  const isIpTracker = animationType === 'ipTracker';
  const needsZoomingVideo = !isIpTracker && isMobile;
  const lang = i18next.language;
  const preparedLink = prepareUrl('/email/' + (funnelLink || ''), lang);
  const $initAnimation = useStore(initAnimation);
  const $prepareAnimation = useStore(prepareAnimation);
  const $phoneData = useStore(phoneData);
  const $resetAnimationGlob = useStore(resetAnimationGlob);

  // useEffect(() => {
  //   initI18Next(i18next, lang);
  // }, [lang]);
  //
  // console.log('lang: ', lang);

  useEffect(() => {
    if (scrollUp) setTimeout(() => scrollToTop(), 100);
  }, [scrollUp]);

  const delay = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    if ($resetAnimationGlob)
      setTimeout(() => {
        prepareAnimation.set(true);
        initAnimation.set(false);
        window.location.reload();
      }, 1000);
  }, [$resetAnimationGlob]);

  const addClass = (element: ElementClassProps, selector: string) => {
    if (element) element.classList.add(styles[selector]);
  };
  const removeClass = (element: ElementClassProps, selector: string) => {
    if (element) element.classList.remove(styles[selector]);
  };

  const redirectToFunnel = () => {
    if (analytics) {
      analytics.event({ action: 'Locate Result' });
    }
    if (typeof window !== 'undefined') {
      window.location.href = preparedLink;
    }
  };

  useGSAP(async () => {
    gsap.config({ nullTargetWarn: false });
    const mainFlow = gsap.timeline({ defaults: { duration: 1, ease: 'linear' } });

    // step 4. Start video and title effects
    const start = () => {
      setActiveAnimation(true);
      const startFlow = gsap.timeline({ defaults: { duration: 0.4, ease: 'linear' } });
      startFlow.to(heroContainer, { display: 'none', opacity: 0, duration: 0.4 });
      startFlow.to(videoRef.current, {
        translateX: 0,
        translateY: isIpTracker ? (isSM ? '-80px' : 0) : isMobile ? '200px' : 0,
        scale: needsZoomingVideo ? 2.2 : 1,
        onCompleteParams: [videoRef.current],
        onComplete: (el) => el.play(),
      });
      startFlow.call(addClass, [animationWrapperRef.current, 'active']);
      startFlow.to(firstStepRef.current, { autoAlpha: 1, duration: 2.4 }, '>1');
      startFlow.call(() => setAnimateAnimation(true));
      startFlow.set(towerRef.current, { display: 'none', opacity: 0, duration: 2.1 }, '>5');
      startFlow.set(phoneRef.current, { display: 'block', duration: 2.1 });

      return startFlow;
    };

    // step 7. Init final flow with results
    const end = () => {
      const endFlow = gsap.timeline({ defaults: { duration: 1, ease: 'linear' } });
      endFlow.to(firstStepRef.current, { autoAlpha: 0, duration: 0.4 }, '>5');
      endFlow.to(videoRef.current, { autoAlpha: 0, scale: isMobile ? 5 : 2 }, '<');
      endFlow.to(mapBgRef.current, { autoAlpha: 1, scale: isMobile ? 1.1 : 1.3 }, '<');
      endFlow.call(() => setRemoveWrapperAnimation(true), undefined, '<');
      endFlow.to(finalStepRef.current, { autoAlpha: 1 }, '>1');
      endFlow.call(() => setFinalStepAnimation(true), undefined, '<');

      return endFlow;
    };

    // step 6. Detecting phone data effect
    const list = (
      element: HTMLSpanElement | null,
      dataType?: string | null,
      seconds = 1,
      blur = true,
    ) => {
      const listFlow = gsap.timeline({ defaults: { duration: 1, ease: 'linear' } });
      const isLocationElement = element?.className.includes('label');

      addClass(element, 'loading');
      removeClass(element, 'blurred');

      if (element) {
        listFlow.to(element.parentElement, { display: 'flex', autoAlpha: 1, duration: 0.4 });
        listFlow.to(element.previousElementSibling, { opacity: 1 }, `>${seconds}`);
        listFlow.call(removeClass, [element, 'loading'], '<');
        if (isIpTracker) {
          if (dataType !== 'available') {
            listFlow.call(addClass, [element, 'label'], '<');
          }
        } else {
          isLocationElement && listFlow.call(addClass, [element, 'label'], '<');
        }

        if (blur && !isIpTracker) {
          listFlow.call(
            (el, type) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              el.innerText = type || ('Hidden data' && addClass(el, 'blurred'));
            },
            [element, dataType],
            '<',
          );
        }
      }

      return listFlow;
    };

    // step 3. Run animation flow
    const flow2 = async ($phoneData: Partial<Record<string, string | null>>) => {
      const timezone =
        currentLanguage?.includes('ar') && isFftbPage && $phoneData?.timezone
          ? `(${$phoneData?.timezone.split('(')[1]}`
          : $phoneData?.timezone;

      mainFlow.add(start());
      mainFlow.to(boxCheckingRef.current, { display: 'none', opacity: 0, duration: 0.4 }, '<2');
      mainFlow.set(listRef.current, { paddingBottom: '44px', duration: 0.4 }, '>');

      if (isIpTracker) {
        mainFlow.add(list(gpsRef.current, 'available', 2), '>');
        mainFlow.add(list(ipRef.current, 'available', 2), '>');
        mainFlow.to(subtitleRef.current, { visibility: 'visible', opacity: 1, duration: 0.4 }, '>');
      }
      // step 5. Detecting phone data effect
      mainFlow.add(list(carrierRef.current, $phoneData?.carrier, 2), '>');
      mainFlow.add(list(countryRef.current, $phoneData?.country, 1), '>-1');
      mainFlow.add(list(timezoneRef.current, timezone, 0.7), '>-1');
      mainFlow.add(list(cityRef.current, $phoneData?.location, 1.3), '>-1');
      mainFlow.add(list(locationRef.current, undefined, 2, false), '>-1');
      if (localStorage.getItem('country') === 'BR' || isIpTracker) {
        mainFlow.add(end(), '<-1');
      } else {
        const getCurrentTime = Date.now();
        sessionStorage.setItem('searchDate', getCurrentTime.toString());
        mainFlow.call(() => {
          redirectToFunnel();
          resetAnimationGlob.set(true);
        });
      }
    };

    // step 2. Reset animation before running new flow
    const resetAnimation = (flowTimeline: GSAPTimeline) => {
      flowTimeline.revert();
      flowTimeline.clear();
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      setWrapperAnimation(false);
      setRemoveWrapperAnimation(false);
      setAnimateAnimation(false);
      setActiveAnimation(false);
      setFinalStepAnimation(false);
      flowTimeline.restart();
    };

    // step 1. Init animation and choose animation type
    if ($initAnimation) {
      switch (animationType) {
        case 'lp':
        case 'main':
        case 'ipTracker':
          resetAnimation(mainFlow);
          setScrollUp(true);
          await delay(1000);
          await flow2($phoneData);
          break;
        default:
          redirectToFunnel();
      }
    }
    if ($prepareAnimation) {
      resetAnimation(mainFlow);
      loadingButton.set(false);
    }
  }, [$initAnimation, $prepareAnimation]);

  return (
    <div
      className={`${styles.animation} ${styles.flat} ${activeAnimation ? styles.active : ''} ${animateAnimation ? styles.animate : ''}`}
      ref={animationContainerRef}
    >
      <div
        className={`${styles.wrapper} ${wrapperAnimation ? styles.active : ''} ${removeWrapperAnimation ? styles.remove : ''}`}
        ref={animationWrapperRef}
      >
        <EarthVideo videoRef={videoRef} />
        <picture>
          <source srcSet={isMobile ? MapMobileWebp.src : MapDesktopWebp.src} type="image/webp" />
          <img
            src={isMobile ? MapMobile.src : MapDesktop.src}
            alt="Map"
            ref={mapBgRef}
            loading="lazy"
            decoding="async"
            className={styles.mapBg}
          />
        </picture>
        <FirstStep
          animationType={animationType}
          phone={$phoneData?.number}
          firstStepData={firstStepData}
          active={activeAnimation}
          mainRef={firstStepRef}
          towerRef={towerRef}
          phoneRef={phoneRef}
          boxCheckingRef={boxCheckingRef}
          listRef={listRef}
          carrierRef={carrierRef}
          countryRef={countryRef}
          timezoneRef={timezoneRef}
          cityRef={cityRef}
          locationRef={locationRef}
          gpsRef={gpsRef}
          ipRef={ipRef}
          subtitleRef={subtitleRef}
        />
        <FinalStep
          animationType={animationType}
          finalStepRef={finalStepRef}
          finalStepData={finalStepData}
          boxAnimate={finalStepAnimation}
          phone={$phoneData?.number}
        />
      </div>
    </div>
  );
};

export default MainAnimation;
